/* =========================================================================
   Our Story
   ========================================================================= */

/* Styles for our story content */
.our-story-content {

  /* Remove section bottom margin by default */
  section {
    margin-bottom: 0;
  }

  /* Make images 100px wide */
  img {
    width: 100px;

    /* Set auto width for images on medium and up devices */
    @include breakpoint($mq-medium) {
      width: auto;
    }
  }

  /* On sections that ask for bottom margin, give them some */
  .bottom-margin {
    margin-bottom: 1.5em;
  }

  /* Don't constain image width in sections that ask for full image */
  .full-image img {
    width: auto;
  }

  /* Give slideshow a width and center with auto left/right margins */
  .slideshow {
    margin-left: auto;
    margin-right: auto;
    max-width: 308px;

    /* Remove left/right margins and make 30% wide on large and up devices */
    @include breakpoint($mq-large) {
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }
  }
}
