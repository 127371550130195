/* =========================================================================
   Content
   ========================================================================= */

/* Styles for the content container */
.content {
  margin-top: 0;
  padding: 0 0.25em;
  text-align: center;

  /* Remove padding on medium and up devices */
  @include breakpoint($mq-medium) {
    padding: 0 0 1.5em;
  }

  /* Give bottom margin to sections */
  section {
    margin-bottom: 1.5em;
  }
}
