/* =========================================================================
   Header
   ========================================================================= */

/* Header | Hero
   ========================================================================= */

/* Styles for the logo "Jahleh + Patrick = 11.1.2014" */
.hero .tagline {
  color: $white;
  font-size: 0.95rem;
  left: 2px;
  opacity: 0.5;
  position: absolute;
  top: 5px;

  /* On medium and up devices, increase font size and move in */
  @include breakpoint($mq-medium) {
    font-size: 1.75rem;
    left: 10px;
    top: 15px;
  }

  /* On large and up devices, increase font size and move down */
  @include breakpoint($mq-large) {
    font-size: 2rem;
  }
}

/* Styles for the hero image */
.hero-image {
  height: auto;
  width: 100%;
}
