/* =========================================================================
   Structure
   ========================================================================= */

/* Structure | Elements
 =========================================================================== */

/* Apply border-box sizing to root */
html {
  box-sizing: border-box;
}

/* Apply border-box sizing so elements take up actual allotted space */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Remove margin and padding from headers */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/* Structure | Classes
 =========================================================================== */

/* Don't show an element with this class */
.hide {
  display: none;
}
