/* =========================================================================
   RSVP
   ========================================================================= */

/* Give some bottom margin to content on RSVP page */
.rsvp-content {
  margin-bottom: 1.5em;
}

/* Change alignment and spacing around form errors */
.rsvp-form-error {
  padding-top: 0.25em;
  text-align: left;
}
