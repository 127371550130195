/* =========================================================================
   Wedding
   ========================================================================= */

/* Make Google Map iframe 100% wide */
.wedding-content {
  iframe {
    width: 100%;

    /* Give iframe fixed with on medium and up devices */
    @include breakpoint($mq-medium) {
      width: 592px;
    }
  }

  /* Put some space below the beachhouse slideshow */
  .slideshow {
    margin: 0 auto 3em;

    /* 60% width constraint on medium and up devices */
    @include breakpoint($mq-medium) {
      width: 60%;
    }

    /* 50% width constraint on medium and up devices */
    @include breakpoint($mq-large) {
      width: 50%;
    }
  }
}
