/* =========================================================================
   Navigation
   ========================================================================= */

/* Styles for navigation container */
.main-nav {
  padding-left: 1px;
  padding-right: 1px;
  text-align: center;

  /* Remove padding on medium and up devices */
  @include breakpoint($mq-medium) {
    padding-left: 0;
    padding-right: 0;
  }

  /* Adjust menu margin */
  .menu {
    margin: -4px 0 1.5em;

    /* Add margin on medium and up devices */
    @include breakpoint($mq-medium) {
      margin: 0.75em 0 3em;
    }

    /* Menu item styles */
    li {
      border: 1px solid $gray-d3;
      font-size: 1.5rem;
      width: 100%;

      /* Alter width on medium and up devices */
      @include breakpoint($mq-medium) {
        width: 5em;
      }
    }

    /* Make links in main navigation the brand navy */
    a,
    a:hover,
    a:visited {
      color: $jp-navy;
    }
  }
}
