/* =========================================================================
   Typography
   ========================================================================= */

/* Typography | Elements
 =========================================================================== */

/* Set font to be Open Sans Condensed */
body {
  font-family: $open-sans-condensed;
}

/* Make paragraph text larger */
p {
  font-size: 1.25rem;
}
