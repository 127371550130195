/* =========================================================================
   Footer
   ========================================================================= */

/* Footer | Colophon
   ========================================================================= */

/* Center align footer text */
.colophon {
  padding: 0.5em 1em 1em;
  text-align: center;

  /* Set font size for text */
  p {
    font-size: 1em;
  }
}
